<template>
  <div v-if="shops">
    <CCard class="shadow-sm">
      <CCardBody>
        <h1 class="text-center font-weight-bold">
          บัตรสะสมแต้ม
        </h1>
        <hr />
        <CRow class="d-flex justify-content-center">
          <CCol md="10">
            <CCard v-if="documents === ''" border-color="dark">
              <CCardBody>
                <table aria-describedby="">
                  <tr>
                    <th scope="col" style="width:30%">
                      <CImg src="../../../img/reward/reward-card.png" class="img-fluid" />
                    </th>
                    <td>
                      <h5>
                        <strong>ลดขั้นตอนที่ยุ่งยาก ประหยัดค่าใช้จ่าย </strong>
                      </h5>
                      <p>
                        จะซื้อ<strong> 5 แถม 1 </strong>หรือจะซื้อ
                        <strong>10 แถม 1</strong>
                        ก็ออกแบบให้ลูกค้าสะสมแต้มได้ง่ายๆ
                        <br />
                        ไม่ต้องพิมพ์บัตรสะสมแต้มแบบกระดาษ
                      </p>
                      <p>
                        ตอบโจทย์ความสะดวกสบายสำหรับร้านค้า
                        เพราะสามารถเลือกสินค้าที่ร่วมรายการสะสมแต้มได้ด้วยตนเองง่ายๆ
                      </p>
                    </td>
                  </tr>
                </table>

                <hr />
                <br />
                <router-link to="/crm/settings#rewardcard">
                  <h5 class="text-center font-weight-bold text-dark">
                    <CIcon name="cil-settings" />
                    ตั้งค่าบัตรสะสมแต้มของร้านค้าคุณ ได้ที่นี่
                  </h5>
                </router-link>
              </CCardBody>
            </CCard>
            <CCard v-else-if="documents !== ''">
              <CCardBody>
                <table aria-describedby="">
                  <tr>
                    <th style="width:50%" scope="col">
                      <CCard border-color="dark">
                        <CCardBody>
                          <h2 class="font-weight-bold text-center" v-if="logo !== ''">
                            <CImg :src="logo" width="25%" class="img-fluid" />
                            {{ shopName }}
                          </h2>
                          <h2 class="font-weight-bold text-center" v-else>
                            <CImg src="../../logo-ab.png" width="70%" />
                          </h2>
                          <p class="font-weight-bold text-center">
                            สะสมครบ {{ pointCard }} {{ unitName }} แลกรับฟรี
                            {{ freeCard }}
                            {{ unitName }}
                          </p>
                          <hr />
                          <div class="text-center">
                            <p v-for="mypoint in 4" :key="mypoint" class="circle" :style="style">
                              {{ mypoint }}
                            </p>
                            <p v-for="allpoint in pointCard - 4" :key="allpoint + 4" class="circle">
                              {{ allpoint + 4 }}
                            </p>
                          </div>
                        </CCardBody>
                      </CCard>
                    </th>
                    <td class="text-center">
                      <h4 class="font-weight-bold ">
                        บัตรสะสมแต้มของร้านค้าคุณ
                      </h4>
                      <br />
                      <h5>
                        สถานะ :
                        <strong class="text-success"> {{ status }} </strong>
                      </h5>
                      <br />
                      <p class="font-weight-bold text-center">
                        สะสมครบ {{ pointCard }} {{ unitName }} แลกรับฟรี
                        {{ freeCard }}
                        {{ unitName }}
                      </p>

                      <p>
                        สินค้าที่ร่วมรายการทั้งหมด
                        {{ items }} รายการ
                      </p>
                      <br />
                      <br />
                      <router-link to="/crm/settings#rewardcard">
                        <p class="font-weight-bold">
                          ตั้งค่าบัตรสะสมแต้มของร้านค้าคุณ ได้ที่นี่
                        </p>
                      </router-link>
                    </td>
                  </tr>
                </table>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import crm from '@/services/crm'
import NoShop from '@/containers/NoShop.vue'

export default {
  components: { NoShop },
  data() {
    return {
      documents: '',
      pointCard: 0,
      unitName: '',
      freeCard: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shops[0]
    },
    shopName() {
      if (this.shop === undefined) {
        return ''
      } else {
        return this.shop.shopName
      }
    },
    logo() {
      if (this.shop === undefined) {
        return ''
      } else {
        return this.shop.remoteImagePath
      }
    },
    style() {
      let stamp = ''
      if (this.logo !== '') {
        stamp = {
          backgroundImage: `url('${this.logo}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      } else {
        stamp = {
          backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0D3aSjZZJPWSoGAOz-Knv8xKupKxBd5eqdQ&usqp=CAU')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }
      }
      return stamp
    },
    status() {
      if (this.documents.isSaved === 1) {
        return 'เปิดใช้งาน'
      } else if (this.documents.isSaved === 0) {
        return 'ฉบับร่าง'
      }
    },
    items() {
      if (this.documents !== '') {
        return this.documents.items.length
      } else {
        return ''
      }
    },
  },
  created() {
    this.getRewardCardByUID()
  },
  methods: {
    getRewardCardByUID() {
      const uid = this.uid

      crm
        .get('/api/v1.0/' + uid + '/rewardcard/getbyuid')
        .then((response) => {
          this.documents = response.data.data.documents

          if (this.documents !== null && this.documents !== undefined) {
            this.pointCard = this.documents.point
            this.freeCard = this.documents.free
            this.unitName = this.documents.unit.name
          } else {
            this.documents = ''
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>

<style>
.circle {
  background: #fff;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #666;
  display: inline-flex;
  /* or inline-flex */
  align-items: center;
  justify-content: center;
}
</style>
